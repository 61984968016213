import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import * as R from 'ramda';
import {
  AppBar as MuiAppBar,
  Grid,
  Box,
  Button,
  Toolbar,
  Typography,
  IconButton,
  CircularProgress,
  useMediaQuery,
  useTheme,
  styled,
} from '@mui/material';
import { Menu } from '@mui/icons-material';

import { Snackbar } from './Snackbar';
import { MoreButton } from './MoreButton';
import { downloadReport } from './fileHandler';

const StyledButton = styled(Button)({
  // background: 'linear-gradient(45deg, rgba(0,0,0,0) 0%, rgba(26,35,126,0) 150%)',
  boxShadow: '4px 2px 11px 2px rgba(52,110,142,0.50)',
  color: 'white',
});

const StyledAppbar = styled(MuiAppBar)({
  // background: 'linear-gradient(36deg, rgba(0,0,0,1) 50%, rgba(77,183,255,1) 250%)',
  background: 'rgba(0,0,0,1)',
});

export const AppBar = ({ handleDrawerToggle }) => {
  const theme = useTheme();
  const { getValues } = useFormContext();
  const history = useHistory();
  const mobileMode = useMediaQuery(theme.breakpoints.down('sm'));
  const [hoverButton, setHoverButton] = useState([false, false, false]);
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState({
    message: undefined,
    color: undefined,
  });

  const [working, setWorking] = useState(false);
  const getReport = async () => {
    setWorking(true);
    const formData = getValues();
    const error = await downloadReport(formData.filename, formData);
    setWorking(false);
    setSnackMessage({ text: error, color: 'error' });
    setSnackOpen(!!error);
  };

  return (
    <>
      <StyledAppbar
        position="fixed"
        sx={{
          zIndex: (themeArg) => themeArg.zIndex.drawer + 1,
          borderBottom: 1,
          borderColor: 'primary.border',
        }}
      >
        <Grid align="center">
          <Toolbar sx={{ maxWidth: 1360 }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 1, display: { sm: 'none' } }}
            >
              <Menu />
            </IconButton>
            <Typography
              variant="h6"
              align="left"
              noWrap
              component="div"
              sx={{ flexGrow: 1 }}
              onClick={() => history.push('/')}
            >
              Rapid Report
            </Typography>
            <Box sx={{ position: 'relative', mr: 2 }}>
              <StyledButton
                variant="outlined"
                size="small"
                onClick={getReport}
                disabled={working}
                // startIcon={<HistoryEdu />}
              >
                Generate
              </StyledButton>
              {working && (
                <CircularProgress
                  size={22}
                  thickness={6}
                  sx={{
                    color: 'primary.progress',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                />
              )}
            </Box>
            {!mobileMode && (
              <>
                <StyledButton
                  variant="outlined"
                  size="small"
                  sx={{ '&:hover': { size: 'large' }, marginRight: 2 }}
                  onMouseOver={() => setHoverButton(R.update(1, true)(hoverButton))}
                  onMouseOut={() => setHoverButton(R.update(1, false)(hoverButton))}
                  // startIcon={<SaveAlt />}
                >
                  Guide
                </StyledButton>
              </>
            )}
            <MoreButton />
          </Toolbar>
        </Grid>
      </StyledAppbar>
      <Snackbar open={snackOpen} setOpen={setSnackOpen} message={snackMessage} />
    </>
  );
};
