import React from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';

export const ChapterStyle = () => {
  const { chapterId } = useParams();
  return (
    <Grid container spacing={2} key={`chapterstyle-${chapterId}`}>
      <Grid item xs={6}>
        theme
      </Grid>
      <Grid item xs={6}>
        palette
      </Grid>
    </Grid>
  );
};
