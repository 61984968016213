import * as React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Switch } from '@mui/material';

export const FormSwitch = (props) => {
  const { control } = useFormContext();
  const { name, defaultValue, defaultChecked, ...other } = props;

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Switch
            {...field}
            defaultValue={defaultValue}
            defaultChecked={defaultChecked}
            {...other}
            color="primary"
            size="small"
          />
        )}
      />
    </>
  );
};
