import React from 'react';
import { useParams } from 'react-router-dom';
import { MenuBook } from '@mui/icons-material';

import { FormCard } from '../ui/FormCard';
import { Swapper } from '../ui/Swapper';

import { indexOf } from '../utils';

export const Chapters = ({ chapters, chapterSwap, chapterDelete, chapterAppend }) => {
  const { chapterId } = useParams();
  const chapterIndex = indexOf(chapters, chapterId);

  return (
    <FormCard
      title={`Chapters `}
      subheader={`${chapterIndex + 1} of ${chapters.length}`}
      chapters={chapters}
      chapterDelete={chapterDelete}
      chapterAppend={chapterAppend}
      actions={<Swapper chapters={chapters} chapterSwap={chapterSwap} />}
      icon=<MenuBook color="primary" />
    />
  );
};
