import React, { useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { useForm, useFieldArray, FormProvider } from 'react-hook-form';
import { Box, Grid } from '@mui/material';

import { Home } from '../pages/Home';
import { Chapters } from '../pages/Chapters';
import { Configuration } from '../pages/Configuration';
import { General } from '../pages/General';
import { AppBar } from './AppBar';
import { Menu } from './Menu';
import { PreviewActions } from './PreviewActions';

export const ReportForm = () => {
  const methods = useForm({
    defaultValues: { includeCover: true, title: 'My Rapid Report', filename: 'rapid-report' },
  });
  const onSubmit = (data) => console.log(JSON.stringify(data, null, 2));

  const [drawerOpen, setDrawerOpen] = useState(false);
  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };
  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  const {
    fields: chapters,
    append: chapterAppend,
    remove: chapterDelete,
    swap: chapterSwap,
  } = useFieldArray({
    keyName: 'key',
    control: methods.control,
    name: 'chapters',
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Router>
          <Box
            sx={{
              position: 'fixed',
              bottom: 0,
              right: 0,
              width: '100%',
              display: 'flex',
              height: '100vh',
              background: 'linear-gradient(0deg, rgba(18,16,20,1) 82%, rgba(110,110,133,1) 160%)',
            }}
          >
            <Grid container justifyContent="center" spacing={0} sx={{ marginRight: 2 }}>
              <AppBar handleDrawerToggle={handleDrawerToggle} />
              <Menu
                chapters={chapters}
                chapterAppend={chapterAppend}
                drawerOpen={drawerOpen}
                closeDrawer={closeDrawer}
              />
              <Switch>
                <Route exact path="/">
                  <Home chapters={chapters} chapterAppend={chapterAppend} />
                </Route>
                <Route>
                  <Route path="/general">
                    <General />
                  </Route>
                  <Route path="/chapters/:chapterId">
                    <Chapters
                      chapters={chapters}
                      chapterSwap={chapterSwap}
                      chapterDelete={chapterDelete}
                      chapterAppend={chapterAppend}
                    />
                  </Route>
                  <Route path="/configuration">
                    <Configuration />
                  </Route>
                  <PreviewActions />
                </Route>
              </Switch>
            </Grid>
          </Box>
        </Router>
      </form>
    </FormProvider>
  );
};
