import React from 'react';

import { FormText } from './FormText';
import { FormSelect } from './FormSelect';
import { ContentFormRow } from './ContentFormRow';

const footerField = <FormText name="footer" label="Footer" sx={{ width: '100%' }} />;

const themes = [{ value: 'marine', label: 'Marine' }];
const themeField = (
  <FormSelect
    name="theme"
    label="Theme"
    menuItems={themes}
    defaultValue=""
    sx={{ width: '100%' }}
  />
);

const palettes = [
  { value: 'earth', label: 'Earth' },
  { value: 'marine', label: 'Marine' },
];
const paletteField = (
  <FormSelect
    name="palette"
    label="Palette"
    menuItems={palettes}
    defaultValue=""
    sx={{ width: '100%' }}
  />
);

export const GeneralFields = () => {
  const rows = [
    { title: 'Footer', col1: footerField, id: 'footer1' },
    { title: 'Theme & Palette', col1: themeField, col2: paletteField, id: 'theme1' },
  ];
  return rows.map((row) => (
    <ContentFormRow
      title={row.title}
      col1={row.col1}
      col2={row.col2}
      key={`general.field.${row.id}`}
    />
  ));
};

//
