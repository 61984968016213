import React, { useState } from 'react';
import {
  SpeedDial,
  SpeedDialIcon,
  SpeedDialAction,
  Dialog,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import PreviewOutlinedIcon from '@mui/icons-material/PreviewOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import GetAppOutlinedIcon from '@mui/icons-material/GetAppOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import CodeIcon from '@mui/icons-material/Code';

import { Preview } from './Preview';

export const PreviewActions = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [modalOpen, setModalOpen] = useState(false);
  const toggleModalOpen = () => {
    setModalOpen(!modalOpen);
  };

  const actions = [
    { icon: <GetAppOutlinedIcon />, tooltipTitle: 'Download report' },
    { icon: <CodeIcon />, tooltipTitle: 'View form', onClick: toggleModalOpen },
    { icon: <ContentCopyOutlinedIcon />, tooltipTitle: 'Copy form' },
  ];

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <SpeedDial
        ariaLabel="Actions"
        icon={<SpeedDialIcon icon={<PreviewOutlinedIcon />} openIcon={<KeyboardArrowDownIcon />} />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        sx={{ marginLeft: -9, marginBottom: 2 }}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.tooltipTitle}
            {...action}
            sx={{ border: 2, borderColor: 'primary.border', background: 'rgba(15,36,50,0.75)' }}
          />
        ))}
      </SpeedDial>

      <Dialog open={modalOpen} onClose={toggleModalOpen} fullScreen={fullScreen} scroll="body">
        <Preview toggleOpen={toggleModalOpen} />
      </Dialog>
    </>
  );
};
