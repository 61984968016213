import * as React from 'react';
import { useParams } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { TabContext } from './PageTabs';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel.content.${index}`}
      aria-labelledby={`tab.content.${index}`}
      {...other}
    >
      <Box sx={{ p: 2 }}>
        <Typography component="span">{children}</Typography>
      </Box>
    </div>
  );
};

export const ContentTabs = ({ headers, content, contentId }) => {
  const { chapterTab } = React.useContext(TabContext);
  const { chapterId } = useParams();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box key={`chapters.${chapterId}.content.${contentId}`}>
      {chapterTab === 1 && (
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} sx={{ marginleft: 'auto' }}>
            {headers.map((header) => (
              <Tab
                label={header.label}
                icon={header.icon}
                key={`chapters.${chapterId}.content-header-${header.label}`}
              />
            ))}
          </Tabs>
          {content.map((item, index) => (
            <TabPanel
              value={value}
              index={index}
              key={`chaptertab-${item.name}.content.${contentId}`}
            >
              {item.component}
            </TabPanel>
          ))}
        </Box>
      )}
    </Box>
  );
};
