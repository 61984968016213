import * as React from 'react';
import { Drawer, Toolbar, Typography, Divider, Box, Grid, IconButton } from '@mui/material';
import { ArrowBackIos } from '@mui/icons-material';

import { DrawerItems } from './DrawerItems';

const drawerWidth = 240;
const version = 'Alpha 0.1.0';

export const Menu = ({ chapters, chapterAppend, drawerOpen, closeDrawer }) => {
  return (
    <>
      <Drawer
        open={drawerOpen}
        onClose={closeDrawer}
        sx={{
          display: { xs: 'block', sm: 'none' },
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            background: 'linear-gradient(0deg, rgba(18,16,20,1) 82%, rgba(110,110,133,1) 160%)',
          },
        }}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <Grid align="right" sx={{ m: 1.25 }}>
          <IconButton onClick={closeDrawer}>
            <ArrowBackIos fontSize="small" />
          </IconButton>
        </Grid>
        <Divider />
        <Typography sx={{ p: 2 }}>{version}</Typography>
        <Divider />
        <DrawerItems chapters={chapters} chapterAppend={chapterAppend} />
        <Divider />
      </Drawer>

      <Box
        sx={{
          color: 'divider',
          borderRight: 1,
          width: 240,
          height: '100%',
          display: { xs: 'none', sm: 'block' },
        }}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <Toolbar />
        <Typography sx={{ p: 2, color: 'text.primary' }}>{version}</Typography>
        <Divider />
        <DrawerItems chapters={chapters} chapterAppend={chapterAppend} />
        <Divider />
      </Box>
    </>
  );
};
