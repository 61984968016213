import React from 'react';
import {
  Accordion,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  styled,
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

const StyledAccordion = styled(Accordion)({
  background: 0,
  '&.MuiAccordion-root:before': {
    height: 0,
  },
});

export const DrawerRow = ({ title, icon, content, open, handleOpen, other }) => (
  <>
    <ListItemButton onClick={handleOpen} {...other} sx={{ color: 'text.primary' }}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={title} />
      {content && (open ? <ExpandLess /> : <ExpandMore />)}
    </ListItemButton>
    {content && (
      <Collapse in={open} timeout="auto">
        <List component="div" disablePadding>
          {content}
        </List>
      </Collapse>
    )}
  </>
);
