import React from 'react';

import { FigureFields } from './FigureFields';
import { TextFields } from './TextFields';

export const ContentFields = ({ chapterIndex, contentIndex, type }) => {
  return type === 'figure' ? (
    <FigureFields chapterIndex={chapterIndex} contentIndex={contentIndex} />
  ) : (
    type === 'text' && <TextFields chapterIndex={chapterIndex} contentIndex={contentIndex} />
  );
};
