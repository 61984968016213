import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useFieldArray, useFormContext } from 'react-hook-form';
import * as R from 'ramda';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Collapse,
  IconButton,
  Paper,
  Tooltip,
  Grid,
  Typography,
} from '@mui/material/';
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  BarChart,
  ShortText,
  AutoStoriesOutlined,
} from '@mui/icons-material';

import { ContentFields } from './ContentFields';
import { indexOf } from '../utils';

const contentButtons = [
  { label: 'Text', value: 'text', icon: <ShortText /> },
  { label: 'Figure', value: 'figure', icon: <BarChart sx={{ color: 'secondary.second' }} /> },
  { label: 'Page break', value: 'pagebreak', icon: <AutoStoriesOutlined color="secondary" /> },
];

const Row = ({ contentItem, chapterIndex, contentIndex }) => {
  const { watch } = useFormContext();
  const description = watch(`chapters.${chapterIndex}.content.${contentIndex}.description`);
  const [open, setOpen] = React.useState(false);
  const typeIcon = R.o(R.prop('icon'), R.find(R.propEq('value', contentItem.type)))(contentButtons);
  const typeLabel = R.o(
    R.prop('label'),
    R.find(R.propEq('value', contentItem.type)),
  )(contentButtons);
  return (
    <>
      <TableRow hover onClick={() => contentItem.type !== 'pagebreak' && setOpen(!open)}>
        <TableCell>
          {contentItem.type !== 'pagebreak' && (
            <IconButton aria-label="expand row" size="small">
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          )}
        </TableCell>
        <TableCell>{description}</TableCell>
        <TableCell>
          <Tooltip title={typeLabel} placement="right">
            {typeIcon}
          </Tooltip>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto">
            <Box sx={{ margin: 1 }}>
              <ContentFields
                chapterIndex={chapterIndex}
                contentIndex={contentIndex}
                type={contentItem.type}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export const CollapsibleTable = ({ chapters }) => {
  const { chapterId } = useParams();
  const chapterIndex = indexOf(chapters, chapterId);

  const { control } = useFormContext();
  const { fields: chapterContent, append: contentAppend } = useFieldArray({
    control,
    name: `chapters.${chapterIndex}.content`,
  });

  const addContent = (type) => {
    contentAppend({
      description: '',
      type,
    });
  };

  return (
    <>
      <Grid container justifyContent="center">
        {contentButtons.map((button) => (
          <Grid item sx={{ margin: 1 }} key={`chapters.${chapterIndex}.button.${button.label}`}>
            <Tooltip title={`Add ${button.label}`} placement="top">
              <IconButton onClick={() => addContent(button.value)} sx={{ fontSize: 18 }}>
                +{button.icon}
              </IconButton>
            </Tooltip>
          </Grid>
        ))}
      </Grid>
      <TableContainer
        component={Paper}
        key={`chaptercontent-${chapterId}`}
        sx={{ maxHeight: '100%', background: 0 }}
      >
        <Table aria-label="collapsible table" size="small">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Description</TableCell>
              <TableCell>Type</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {chapterContent.map((contentItem, index) => (
              <Row
                contentItem={contentItem}
                chapterIndex={chapterIndex}
                contentIndex={index}
                key={`chapter.${chapterIndex}.content.${contentItem.id}`}
              />
            ))}
          </TableBody>
        </Table>
        {chapterContent.length === 0 && (
          <>
            <Typography align="center" sx={{ marginTop: 2 }} color="gray">
              Chapter has no content
            </Typography>
            <Typography align="center" sx={{ marginTop: 2 }} color="gray">
              Click one of the content types above to get started
            </Typography>
          </>
        )}
      </TableContainer>
    </>
  );
};
