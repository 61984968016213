import * as React from 'react';
import * as R from 'ramda';
import { useParams } from 'react-router-dom';
import { Stack, IconButton, Tooltip } from '@mui/material';
import { ArrowDropUpRounded, ArrowDropDownRounded } from '@mui/icons-material';

import { indexOf } from '../utils';

export const Swapper = ({ chapters, chapterSwap }) => {
  const { chapterId } = useParams();
  const index = indexOf(chapters, chapterId);
  return (
    <Stack spacing={0} key={`swapper-${chapterId}`}>
      <Tooltip title="Move chapter up" placement="left">
        <IconButton
          size="small"
          onClick={() => chapterSwap(index, R.clamp(0, chapters.length - 1)(index - 1))}
        >
          <ArrowDropUpRounded />
        </IconButton>
      </Tooltip>
      <Tooltip title="Move chapter down" placement="left">
        <IconButton
          size="small"
          onClick={() => chapterSwap(index, R.clamp(0, chapters.length - 1)(index + 1))}
        >
          <ArrowDropDownRounded />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};
