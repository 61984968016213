import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { List, ListSubheader, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { Link, useHistory } from 'react-router-dom';
import * as R from 'ramda';
import { MenuBook, Add, StarOutline, Settings } from '@mui/icons-material';

import { FormSwitch } from './FormSwitch';
import { DrawerRow } from './DrawerRow';

import { generateString } from '../utils';

const toggleIndex = (index) => (state) => {
  const result = Array(4).fill(false);
  return R.update(index, !state[index])(result);
};

export const DrawerItems = ({ chapters, chapterAppend }) => {
  const { watch } = useFormContext();
  const [open, setOpen] = useState([false, false, false, false]);
  const handleOpen = (index) => () => {
    setOpen(toggleIndex(index));
  };

  const history = useHistory();

  const addChapter = () => {
    const chapterId = generateString();
    chapterAppend({ title: `Chapter ${chapters.length + 1}`, include: true, id: chapterId });
    history.push(`/chapters/${chapterId}`);
  };

  const include = (chapterIndex) => watch(`chapters.${chapterIndex}.include`);

  const chapterDrawer = (
    <>
      <ListItemText primary="" />
      {chapters.map((field, index) => (
        <ListItem
          sx={{ color: 'text.secondary' }}
          key={field.id}
          secondaryAction={
            <FormSwitch
              name={`chapters.${index}.include`}
              defaultValue={field.include}
              checked={include(index)}
              edge="end"
            />
          }
          disablePadding
        >
          <ListItemButton sx={{ pl: 4 }} component={Link} to={`/chapters/${field.id}`}>
            <ListItemText primary={field.title} />
          </ListItemButton>
        </ListItem>
      ))}

      <ListItemButton onClick={() => addChapter()} sx={{ justifyContent: 'center' }}>
        <Add color="primary" fontSize="small" />
      </ListItemButton>
    </>
  );

  const drawers = [
    {
      title: 'General',
      icon: <StarOutline />,
      other: {
        component: Link,
        to: '/general',
      },
    },
    {
      title: 'Chapters',
      icon: <MenuBook />,
      content: chapterDrawer,
      open: open[1],
      handleOpen: handleOpen(1),
    },
    {
      title: 'Configuration',
      icon: <Settings />,
      other: {
        component: Link,
        to: '/configuration',
      },
    },
  ];

  return (
    <List
      sx={{ width: '100%', maxWidth: 300 }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={<ListSubheader component="div" id="nested-list-subheader" />}
    >
      {drawers.map((drawer) => (
        <DrawerRow
          title={drawer.title}
          icon={drawer.icon}
          content={drawer.content}
          open={drawer.open}
          handleOpen={drawer.handleOpen}
          other={drawer.other}
          key={`drawer.${drawer.title}`}
        />
      ))}
    </List>
  );
};
