import React from 'react';

import { ContentTabs } from './ContentTabs';
import { FigureGeneralFields } from './FigureGeneralFields';
import { DatasetFields } from './DatasetFields';

const tabHeaders = [{ label: 'settings' }, { label: 'data' }];

const tabContent = (chapterIndex, contentIndex) => {
  return [
    {
      name: 'settings',
      component: <FigureGeneralFields chapterIndex={chapterIndex} contentIndex={contentIndex} />,
    },
    {
      name: 'data',
      component: (
        <DatasetFields chapterIndex={chapterIndex} contentIndex={contentIndex} maxEntities={5} />
      ),
    },
  ];
};

export const FigureFields = ({ chapterIndex, contentIndex }) => {
  return (
    <ContentTabs
      headers={tabHeaders}
      content={tabContent(chapterIndex, contentIndex)}
      contentIndex={contentIndex}
    />
  );
};
