import React, { useState } from 'react';
import * as R from 'ramda';
import { useHistory } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import {
  IconButton,
  Tooltip,
  Typography,
  Stack,
  Dialog,
  Grid,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Delete, Code, Visibility, ContentCopy, VisibilityOff } from '@mui/icons-material/';

import { Preview } from './Preview';
import { Confirm } from './Confirm';
import { generateString } from '../utils';

export const ButtonBar = ({ chapterIndex, chapterDelete, chapterAppend }) => {
  const { watch, setValue, getValues } = useFormContext();
  const theme = useTheme();
  const history = useHistory();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const chapterName = getValues(`chapters.${chapterIndex}.title`);

  const include = watch(`chapters.${chapterIndex}.include`);
  const toggleInclude = () => setValue(`chapters.${chapterIndex}.include`, !include);
  const includeIcon = include ? <Visibility /> : <VisibilityOff />;
  const includeTooltip = include ? 'Exclude' : 'Include';

  const [previewDialogOpen, setPreviewDialogOpen] = useState(false);
  const togglePreviewDialogOpen = () => {
    setPreviewDialogOpen(!previewDialogOpen);
  };

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const toggleDeleteDialogOpen = () => {
    setDeleteDialogOpen(!deleteDialogOpen);
  };

  const cloneChapter = () => {
    const data = getValues(`chapters.${chapterIndex}`);
    const chapterId = generateString();
    const newData = R.evolve({ title: () => `${data.title} (clone)`, id: () => chapterId }, data);
    chapterAppend(newData);
    history.push(`/chapters/${chapterId}`);
  };

  const buttonsEnd = [
    {
      icon: includeIcon,
      tooltip: includeTooltip,
      onClick: toggleInclude,
    },
    { icon: <Code />, tooltip: 'Show JSON', onClick: togglePreviewDialogOpen },
    { icon: <ContentCopy />, tooltip: 'Clone', onClick: cloneChapter },
    { icon: <Delete />, tooltip: 'Delete', onClick: toggleDeleteDialogOpen },
  ];

  const deleteChapter = (index) => {
    chapterDelete(index);
    history.push('/chapters/');
  };

  return (
    <>
      <Stack direction="row" justifyContent="flex-end" sx={{ marginRight: 0.75 }}>
        {buttonsEnd.map((button) => (
          <Tooltip key={button.tooltip} title={button.tooltip}>
            <IconButton onClick={button.onClick} {...button.other}>
              {button.icon}
            </IconButton>
          </Tooltip>
        ))}
      </Stack>
      <Dialog open={previewDialogOpen} onClose={togglePreviewDialogOpen} fullScreen={fullScreen}>
        <Preview toggleOpen={togglePreviewDialogOpen} chapterIndex={chapterIndex} />
      </Dialog>
      <Dialog open={deleteDialogOpen} onClose={toggleDeleteDialogOpen} fullScreen={fullScreen}>
        <Confirm
          message={
            <Grid align="center">
              <Typography gutterBottom sx={{ marginBottom: 3, marginTop: 2 }}>
                I want to delete the following chapter:
              </Typography>
              <Typography>{chapterName}</Typography>
            </Grid>
          }
          toggleOpen={toggleDeleteDialogOpen}
          onAgree={deleteChapter}
        />
      </Dialog>
    </>
  );
};
