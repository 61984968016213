import React, { useRef } from 'react';
import { useFormContext } from 'react-hook-form';

import { appUrl } from '../utils';
import { cleanFormData, removeExcluded } from './cleanFormData';

export const useLoadDialog = () => {
  const { reset } = useFormContext();
  const inputFile = useRef(null);

  async function readJSON(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = (event) => resolve(JSON.parse(event.target.result));
      fileReader.onerror = (error) => reject(error);
      fileReader.readAsText(file);
    });
  }

  const handleFileUpload = (e) => {
    const { files } = e.target;
    if (files && files.length) {
      readJSON(files[0]).then((data) => reset(data));
    }
  };

  const openLoadDialog = () => {
    inputFile.current.click();
  };

  return {
    openLoadDialog,
    loadInput: (
      <input
        style={{ display: 'none' }}
        accept=".json"
        ref={inputFile}
        onChange={handleFileUpload}
        type="file"
      />
    ),
  };
};

const saveFile = (filename, extention, blob) => {
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.download = `${filename}.${extention}`;
  link.href = url;
  link.click();
};

export const saveJson = (filename, formData) => {
  const jsonData = JSON.stringify(formData);
  const blob = new Blob([jsonData], { type: 'text/plain' });
  saveFile(filename, 'json', blob);
};

export const downloadReport = async (fileName, formData) => {
  try {
    await fetch(appUrl('report-generator', '/getReport'), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ config: removeExcluded(cleanFormData(formData)) }),
    })
      .then((response) => response.blob())
      .then((response) => saveFile(fileName, 'pdf', response));
  } catch (error) {
    return error.message;
  }
  return undefined;
};
