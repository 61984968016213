import * as React from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';

import { ReportForm } from './ui/ReportForm';

import { useSwitchableTheme, ColorModeContext } from './theme';

export const App = () => {
  const { theme, colorMode } = useSwitchableTheme();
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ReportForm />
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};
