import * as React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { TextField, MenuItem } from '@mui/material';

export const FormSelect = ({ name, label, menuItems, defaultValue = '', ...other }) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <TextField {...field} select label={label} {...other} defaultValue={defaultValue}>
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {menuItems.map((item) => (
            <MenuItem value={item.value} key={`menuitem.${name}.${item.label}`}>
              {item.label}
            </MenuItem>
          ))}
        </TextField>
      )}
    />
  );
};
