import * as R from 'ramda';

const excludePropIf = {
  space_a: R.equals(0),
  space_b: R.equals(0),
  description: R.T,
  '*': R.equals(''),
};

const withoutExcludedProps = R.pipe(
  R.toPairs,
  R.filter(([k, v]) =>
    R.o(R.not, R.converge(R.or, [R.propOr(R.F, k)(excludePropIf), R.prop('*')(excludePropIf)]))(v),
  ),
  R.fromPairs,
);

const onlyIncluded = R.filter(R.propOr(true, 'include'));

export const cleanFormData = R.compose(
  R.evolve({
    chapters: R.map(
      R.o(
        R.evolve({
          content: R.map(
            R.o(
              R.evolve({
                datasets: R.map(withoutExcludedProps),
              }),
              withoutExcludedProps,
            ),
          ),
        }),
        withoutExcludedProps,
      ),
    ),
  }),
  withoutExcludedProps,
);

export const removeExcluded = R.evolve({
  chapters: R.pipe(
    onlyIncluded,
    R.map(
      R.evolve({
        content: onlyIncluded,
      }),
    ),
  ),
});
