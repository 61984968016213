import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Grid,
  Toolbar,
  Card,
  CardContent,
  CardMedia,
  Typography,
  CardActionArea,
  Divider,
} from '@mui/material';

import { generateString } from '../utils';
import { useLoadDialog } from '../ui/fileHandler';

import imgNew from '../new.jpg';
import imgLoad from '../load.jpg';
import imgTemplates from '../templates.jpg';
import imgGuide from '../guide.jpg';

export const Home = ({ chapters, chapterAppend }) => {
  const history = useHistory();
  const addChapter = () => {
    const chapterId = generateString();
    chapterAppend({ title: `Chapter ${chapters.length + 1}`, include: true, id: chapterId });
    history.push(`/chapters/${chapterId}`);
  };

  const { openLoadDialog, loadInput } = useLoadDialog();

  const images = [
    {
      img: imgNew,
      title: 'New',
      description: 'Start from scratch to build up a new report.',
      onClick: addChapter,
    },
    {
      img: imgLoad,
      title: 'Load',
      description: 'Continue with one of your existing projects.',
      src: loadInput,
      onClick: openLoadDialog,
    },
    {
      img: imgTemplates,
      title: 'Templates',
      description: 'Get going quickly with a template.',
    },
    {
      img: imgGuide,
      title: 'Guide',
      description: 'Get some assistance from the guide.',
    },
  ];

  return (
    <Box sx={{ flex: 1, maxWidth: 1084 }} key="home">
      <Toolbar />
      <Typography gutterBottom variant="h5" component="div" sx={{ margin: 4, marginBottom: 0 }}>
        Get started with Rapid Report
      </Typography>

      <Typography
        variant="body2"
        color="text.secondary"
        sx={{ margin: 4, marginBottom: 3, marginTop: 1 }}
      >
        Easily create and automate professional reports. Choose from different themes and templates.
        Track your production and become a data-driven business.
      </Typography>
      <Divider />
      <Grid container>
        <Grid item xs={12}>
          <Grid container>
            {images.map((item) => (
              <Grid item xs={6} sm={12} md={6} key={item.title}>
                <Card
                  sx={{
                    maxWidth: { xs: '94%', md: '95%' },
                    margin: { xs: 1, md: 3 },
                  }}
                  onClick={item.onClick}
                >
                  {item.src}
                  <CardActionArea>
                    <CardMedia component="img" height={185} src={item.img} alt={item.title} />
                    <CardContent>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        sx={{ textAlign: 'left' }}
                      >
                        {item.title}
                      </Typography>
                      <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'left' }}>
                        {item.description}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
