import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import {
  useTheme,
  useMediaQuery,
  styled,
  IconButton,
  Menu,
  MenuItem,
  Divider,
  Dialog,
  Grid,
  Typography,
} from '@mui/material';
import {
  Add,
  Edit,
  MoreVert,
  SaveAlt,
  LightModeOutlined,
  NightlightOutlined,
} from '@mui/icons-material/';

import { ColorModeContext } from '../theme';
import { Confirm } from './Confirm';
import { saveJson, useLoadDialog } from './fileHandler';

const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 0,
    marginTop: theme.spacing(1),
    minWidth: 228,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow: [
      'rgb(255, 255, 255) 0px 0px 0px 0px',
      'rgba(0, 0, 0, 0.1) 0px 0px 0px 1px',
      'rgba(0, 0, 0, 0.2) 0px 10px 15px -3px',
      'rgba(0, 0, 0, 0.1) 0px 4px 6px -2px',
    ].join(','),
    '& .MuiMenu-list': {
      padding: '10px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
    },
  },
}));

export const MoreButton = () => {
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);
  const { getValues, reset } = useFormContext();
  const formData = getValues();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const saveForm = () => {
    saveJson(formData.title, formData);
    setAnchorEl(null);
  };

  const { openLoadDialog, loadInput } = useLoadDialog();

  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [loadDialogOpen, setLoadDialogOpen] = useState(false);
  const toggleLoadDialogOpen = () => {
    setLoadDialogOpen(!loadDialogOpen);
  };
  const [newDialogOpen, setNewDialogOpen] = useState(false);
  const toggleNewDialogOpen = () => {
    setNewDialogOpen(!newDialogOpen);
  };
  const resetForm = () => {
    toggleNewDialogOpen();
    reset({ title: '' });
    setAnchorEl(null);
    history.push('/');
  };

  return (
    <>
      <IconButton
        size="large"
        color="inherit"
        sx={{ mr: -1 }}
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreVert />
      </IconButton>

      <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={toggleNewDialogOpen}>
          <Add />
          New
        </MenuItem>
        <MenuItem onClick={saveForm}>
          <SaveAlt />
          Save
        </MenuItem>
        <MenuItem onClick={toggleLoadDialogOpen}>
          {loadInput}
          <Edit />
          Load
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === 'dark' ? (
            <>
              <LightModeOutlined fontSize="small" />
              Light mode
            </>
          ) : (
            <>
              <NightlightOutlined fontSize="small" />
              Dark mode
            </>
          )}
        </MenuItem>
      </StyledMenu>

      <Dialog open={newDialogOpen} onClose={toggleNewDialogOpen} fullScreen={fullScreen}>
        <Confirm
          message={
            <Grid align="center">
              <Typography gutterBottom sx={{ marginBottom: 3, marginTop: 2 }}>
                Create a new report?
              </Typography>
              <Typography>This will remove any unsaved data</Typography>
            </Grid>
          }
          toggleOpen={toggleNewDialogOpen}
          onAgree={resetForm}
        />
      </Dialog>

      <Dialog open={loadDialogOpen} onClose={toggleLoadDialogOpen} fullScreen={fullScreen}>
        <Confirm
          message={
            <Grid align="center">
              <Typography gutterBottom sx={{ marginBottom: 3, marginTop: 2 }}>
                Load report?
              </Typography>
              <Typography>This will remove any unsaved data</Typography>
            </Grid>
          }
          toggleOpen={toggleLoadDialogOpen}
          onAgree={openLoadDialog}
        />
      </Dialog>
    </>
  );
};
