import * as React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { TextField } from '@mui/material';

import { MobileDateTimePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

export const FormDateTimePicker = (props) => {
  const { control } = useFormContext();
  const { name, label, defaultValue = '', other } = props;
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MobileDateTimePicker
              disableFuture
              {...field}
              label={label}
              renderInput={(params) => <TextField {...params} sx={{ width: '100%' }} />}
              {...other}
            />
          </LocalizationProvider>
        </>
      )}
    />
  );
};
