import React, { useMemo } from 'react';
import { createTheme } from '@mui/material';
import createPersistedState from 'use-persisted-state';
import { green, lightBlue, blue, cyan, grey, orange, purple } from '@mui/material/colors';

export const ColorModeContext = React.createContext();
const themeColorMode = createPersistedState('mode');

export const useSwitchableTheme = () => {
  const [mode, setMode] = themeColorMode('light');

  const lightPalette = {
    // palette values for light mode
    primary: { main: 'rgba(85,7,68,1)' },
    secondary: { main: lightBlue[900] },

    // paper: { main: 'rgba(61,100,214,1)' },

    text: {
      primary: grey[900],
      secondary: grey[700],
    },
    background: {
      // default: '#d1e8ff',
      // paper: '#d5e1ff',
    },
  };

  const darkPalette = {
    // // palette values for dark mode
    primary: {
      main: 'rgba(72,176,225,1)',
      border: 'rgba(38,92,126,1)',
      progress: 'rgba(179,229,252,1)',
    },
    secondary: {
      main: purple[300],
      second: orange[500],
    },

    greens: { main: green },
    // divider: 'rgba(26,35,126,1)',
    background: {
      // default: 'rgba(0,0,0,1)',
      // paper: 'rgba(9,16,18,1)',
    },

    text: {
      // primary: grey[200],
      secondary: grey[500],
    },
  };

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          ...(mode === 'light' ? lightPalette : darkPalette),
        },
      }),
    [mode],
  );

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => {
          return prevMode === 'light' ? 'dark' : 'light';
        });
      },
    }),
    [setMode],
  );
  return useMemo(() => ({ theme, colorMode }), [theme, colorMode]);
};
