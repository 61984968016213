import * as React from 'react';
import { useParams } from 'react-router-dom';
import {
  CardHeader,
  CardContent,
  CardActions,
  Collapse,
  IconButton,
  Divider,
  Toolbar,
  Box,
  styled,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FactCheckOutlined, Edit, PaletteOutlined } from '@mui/icons-material/';

import { PageTabs, TabPanel, TabContext } from './PageTabs';
import { ChapterFields } from './ChapterFields';
import { CollapsibleTable } from './ChapterContent';
import { ChapterStyle } from './ChapterStyle';
import { ButtonBar } from './ButtonBar';
import { StyledCard } from './StyledCard';
import { indexOf } from '../utils';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export const FormCard = ({
  title,
  subheader,
  icon,
  advancedFields,
  chapters,
  actions,
  chapterDelete,
  chapterAppend,
}) => {
  const { chapterId } = useParams();
  const chapterIndex = indexOf(chapters, chapterId);
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const tabHeaders = [
    { label: 'features', icon: <FactCheckOutlined /> },
    { label: 'content', icon: <Edit /> },
    { label: 'Theme & Palette', icon: <PaletteOutlined /> },
  ];

  const tabContent = [
    { name: 'features', component: <ChapterFields chapters={chapters} /> },
    { name: 'content', component: <CollapsibleTable chapters={chapters} /> },
    { name: 'style', component: <ChapterStyle /> },
  ];

  const [chapterTab, setChapterTab] = React.useState(0);

  return (
    <Box sx={{ flex: 1, maxWidth: 1100 }} key={`formcard-${chapterId}`}>
      <TabContext.Provider value={{ chapterTab }}>
        <Toolbar />
        <CardHeader
          avatar={icon}
          title={title}
          subheader={subheader}
          action={actions}
          sx={{ height: 92 }}
        />
        <Divider />
        <ButtonBar
          chapterIndex={chapterIndex}
          chapterDelete={chapterDelete}
          chapterAppend={chapterAppend}
        />
        <PageTabs
          headers={tabHeaders}
          tabIndex={chapterTab}
          setTabIndex={setChapterTab}
          keyPrefix={`chapter.${chapterIndex}`}
        />
        <StyledCard>
          {tabContent.map((item, index) => (
            <TabPanel value={chapterTab} index={index} key={`chapter.${chapterIndex}.${item.name}`}>
              {item.component}
            </TabPanel>
          ))}
          {advancedFields && (
            <CardActions disableSpacing>
              <ExpandMore expand={expanded} onClick={handleExpandClick} aria-expanded={expanded}>
                <ExpandMoreIcon />
              </ExpandMore>
            </CardActions>
          )}
          <Collapse in={expanded} timeout="auto">
            <CardContent>
              <Divider sx={{ margin: '0px 0px 6px 0px' }}>Advanced</Divider>
              {advancedFields}
            </CardContent>
          </Collapse>
        </StyledCard>
      </TabContext.Provider>
    </Box>
  );
};
