import * as React from 'react';
import { Box, Tabs, Tab, Typography } from '@mui/material';

export const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      <Box sx={{ p: 2 }}>
        <Typography component="span">{children}</Typography>
      </Box>
    </div>
  );
};

export const TabContext = React.createContext();

export const PageTabs = ({ headers, tabIndex, setTabIndex, keyPrefix }) => {
  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <Tabs value={tabIndex} onChange={handleChange}>
      {headers.map((header) => (
        <Tab
          label={header.label}
          icon={header.icon}
          key={`${keyPrefix}.${header.label}`}
          sx={{ marginLeft: 1.8 }}
        />
      ))}
    </Tabs>
  );
};
