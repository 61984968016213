import * as React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Slider, Typography } from '@mui/material';

export const FormSlider = ({
  name,
  label,
  min = 0,
  max = 50,
  step = 1,
  defaultValue = 0,
  ...other
}) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <>
          <Typography variant="inherit">{label}</Typography>
          <Slider
            {...field}
            valueLabelDisplay="auto"
            min={min}
            max={max}
            step={step}
            {...other}
            defaultValue={defaultValue}
          />
        </>
      )}
    />
  );
};
