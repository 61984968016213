import React from 'react';

import { Grid, Box } from '@mui/material';

import { FormText } from './FormText';
import { FormSlider } from './FormSlider';
import { FormTitle } from './FormTitle';
import { range } from '../utils';

const maxSpace = 20;
const spaceMarks = range(0, maxSpace + 1, 5).map((tick) => ({
  value: tick,
  label: tick,
}));

export const PieChartFields = ({ chapterIndex, contentIndex }) => {
  return (
    <>
      <Box
        sx={{ flex: 1, padding: 2 }}
        key={`chapters.${chapterIndex}.content.${contentIndex}.piechartfields`}
      >
        <Grid container spacing={4}>
          <FormTitle title="Title & labels" />
          <Grid container item spacing={3} md={4}>
            <Grid item xs={12} md={12}>
              <FormText
                name={`chapters.${chapterIndex}.content.${contentIndex}.title`}
                label="Title"
                sx={{ width: '100%' }}
                inputProps={{ maxLength: 40 }}
              />
            </Grid>
          </Grid>
          <Grid container item marginTop={4} md={4} spacing={3} />
        </Grid>
      </Box>

      <Box sx={{ flex: 1, padding: 2 }}>
        <Grid
          container
          spacing={4}
          key={`chapters.${chapterIndex}.content.${contentIndex}.piefields`}
        >
          <FormTitle title="Layout" />
          <Grid container item spacing={3} md={4} align="center">
            <Grid item xs={12} md={12}>
              <FormSlider
                name={`chapters.${chapterIndex}.content.${contentIndex}.space_b`}
                label="Top Margin"
                max={maxSpace}
                marks={spaceMarks}
                size="small"
              />
            </Grid>
          </Grid>
          <Grid container item md={4} spacing={3} align="center">
            <Grid item xs={12} md={12}>
              <FormSlider
                name={`chapters.${chapterIndex}.content.${contentIndex}.space_a`}
                label="Bottom Margin"
                max={maxSpace}
                marks={spaceMarks}
                size="small"
                type="hidden"
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
