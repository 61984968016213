import React from 'react';
import { DialogTitle, Box, Button, Grid, Divider } from '@mui/material';
import { ErrorOutline } from '@mui/icons-material';

export const Confirm = ({
  toggleOpen,
  onAgree,
  title = 'Confirm',
  message = 'I want to perform this action',
}) => {
  return (
    <>
      <DialogTitle backgroundColor="inherit">
        <ErrorOutline
          fontSize="large"
          sx={{ marginBottom: -1.1, marginRight: 1, marginLeft: -1 }}
        />
        {title}
      </DialogTitle>
      <Box sx={{ minWidth: 400, minHeight: 125, padding: 3 }} align="center">
        {message}
      </Box>

      <Grid align="center">
        <Button variant="text" sx={{ width: '50%', flex: 1, padding: 1.5 }} onClick={toggleOpen}>
          Cancel
        </Button>
        <Button variant="text" sx={{ width: '50%', flex: 1, padding: 1.5 }} onClick={onAgree}>
          Agree
        </Button>
      </Grid>
    </>
  );
};
