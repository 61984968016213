import React from 'react';
import { Box, Grid } from '@mui/material';

import { FormTitle } from './FormTitle';

export const ContentFormRow = ({ title = '', col1, col2, id }) => {
  return (
    <Box sx={{ flex: 1, padding: 2 }} key={id}>
      <Grid container spacing={4}>
        <FormTitle title={title} />
        <Grid container item spacing={3} md={4}>
          <Grid item xs={12} md={12}>
            {col1}
          </Grid>
        </Grid>
        <Grid container item spacing={3} md={4}>
          <Grid item xs={12} md={12}>
            {col2}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
