import React, { useState } from 'react';
import {
  DialogTitle,
  DialogContent,
  IconButton,
  Switch,
  Typography,
  Toolbar,
  Card,
  Grid,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { useFormContext } from 'react-hook-form';

import { cleanFormData, removeExcluded } from './cleanFormData';

export const Preview = ({ toggleOpen, chapterIndex }) => {
  const { getValues } = useFormContext();
  const data =
    typeof chapterIndex === 'number' ? getValues(`chapters.${chapterIndex}`) : getValues();

  const [clean, setClean] = useState(true);
  const toggleClean = () => {
    setClean(!clean);
  };

  const [hideExcluded, setHideExcluded] = useState(true);
  const toggleHideExcluded = () => {
    setHideExcluded(!hideExcluded);
  };

  const switches = [
    { title: 'Clean', state: clean, onChange: toggleClean },
    { title: 'Hide disabled', state: hideExcluded, onChange: toggleHideExcluded },
  ];

  const previewData = clean
    ? hideExcluded
      ? removeExcluded(cleanFormData(data))
      : cleanFormData(data)
    : hideExcluded
    ? removeExcluded(data)
    : data;

  return (
    <>
      <DialogTitle backgroundColor="inherit" sx={{ p: 0, minWidth: 400 }}>
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            align="left"
            noWrap
            component="div"
            sx={{ marginLeft: 2, flexGrow: 1 }}
          >
            Form data
          </Typography>

          <IconButton onClick={toggleOpen}>
            <Close />
          </IconButton>
        </Toolbar>
      </DialogTitle>

      <DialogContent>
        <Grid container align="center" sx={{ p: 2 }}>
          {switches.map((item) => (
            <Grid container item xs={6}>
              <Typography align="left" key={item.title}>
                {item.title}
              </Typography>
              <Switch size="small" checked={item.state} onChange={item.onChange} />
            </Grid>
          ))}
        </Grid>
        <Card sx={{ p: 2 }}>
          <pre>{JSON.stringify(previewData, null, 3)}</pre>
        </Card>
      </DialogContent>
    </>
  );
};
