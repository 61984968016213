import React from 'react';
import { Box, Grid, Tooltip } from '@mui/material';

import { ContentTabs } from './ContentTabs';
import { FigureGeneralFields } from './FigureGeneralFields';
import { DatasetFields } from './DatasetFields';
import { FormSelect } from './FormSelect';
import { FormText } from './FormText';
import { FormTitle } from './FormTitle';

const contentTypes = [
  { value: 'text', label: 'Text' },
  { value: 'figure', label: 'Figure' },
  { value: 'pagebreak', label: 'Page break' },
];

export const TextFields = ({ chapterIndex, contentIndex }) => {
  return (
    <>
      <Box
        sx={{ flex: 1, padding: 2 }}
        key={`chapters.${chapterIndex}.content.${contentIndex}.fields`}
      >
        <Grid container spacing={4} sx={{ marginBottom: 5 }}>
          <FormTitle title="General" />
          <Grid container item spacing={3} md={4}>
            <Grid item xs={12} md={12}>
              <FormSelect
                name={`chapters.${chapterIndex}.content.${contentIndex}.type`}
                label="Type"
                menuItems={contentTypes}
                sx={{ width: '100%' }}
                disabled
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Tooltip
              title="Only to help you track your contents in Rapid Report."
              placement="bottom"
            >
              <>
                <FormText
                  sx={{ width: '100%', height: '10ch', paddingBottom: 16 }}
                  name={`chapters.${chapterIndex}.content.${contentIndex}.description`}
                  label="Description"
                  multiline
                  rows={4.5}
                  inputProps={{ maxLength: 80 }}
                />
              </>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <FormTitle title="Value" />
          <Grid container item spacing={3} md={8}>
            <Grid item xs={12} md={12}>
              <FormText
                name={`chapters.${chapterIndex}.content.${contentIndex}.text`}
                menuItems={contentTypes}
                sx={{ width: '100%' }}
                inputProps={{ maxLength: 2000 }}
                multiline
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
