import { Grid, Typography } from '@mui/material';

export const FormTitle = ({ title, variant = 'subtitle1' }) => {
  return (
    <Grid container item spacing={3} md={4}>
      <Grid item xs={11} md={11}>
        <Typography variant={variant} sx={{ marginTop: 0, textAlign: 'left' }}>
          {title}
        </Typography>
      </Grid>
    </Grid>
  );
};

// <Divider orientation="vertical" sx={{ height: '90%', marginTop: 2 }} />
