import * as React from 'react';
import { CardHeader, Toolbar, Box, Divider } from '@mui/material';

import { ConfigurationFields } from './ConfigurationFields';
import { StyledCard } from './StyledCard';

export const ConfigurationFormCard = ({ title, subheader, icon }) => {
  return (
    <Box sx={{ flex: 1, maxWidth: 1100 }} key="formcard.configuration">
      <Toolbar />
      <CardHeader avatar={icon} title={title} subheader={subheader} sx={{ flex: 1, height: 92 }} />
      <Divider />
      <StyledCard sx={{ marginTop: 1.5 }}>
        <ConfigurationFields />
      </StyledCard>
    </Box>
  );
};
