import React from 'react';

import { FormText } from './FormText';
import { ContentFormRow } from './ContentFormRow';

export const CoverPageFields = () => {
  const titleField = <FormText name="title" label="Title" sx={{ width: '100%' }} />;
  const subtitleField = <FormText name="subtitle" label="Subtitle" sx={{ width: '100%' }} />;
  const companyNameField = (
    <FormText name="company_name" label="Company name" sx={{ width: '100%' }} />
  );

  const rows = [
    { title: 'Titles', col1: titleField, col2: subtitleField, id: 'title1' },
    { title: '', col1: companyNameField, id: 'title2' },
  ];
  return rows.map((row) => (
    <ContentFormRow
      title={row.title}
      col1={row.col1}
      col2={row.col2}
      key={`general.coverpage.field.${row.id}`}
    />
  ));
};
