import React from 'react';
import { useParams } from 'react-router-dom';

import { FormText } from './FormText';
import { ContentFormRow } from './ContentFormRow';
import { indexOf } from '../utils';

export const ChapterFields = ({ chapters }) => {
  const { chapterId } = useParams();
  const index = indexOf(chapters, chapterId);
  const titleField = (
    <FormText name={`chapters.${index}.title`} label="Title" sx={{ width: '100%' }} />
  );
  const subtitleField = (
    <FormText name={`chapters.${index}.subtitle`} label="Subtitle" sx={{ width: '100%' }} />
  );

  const rows = [{ title: 'Titles', col1: titleField, col2: subtitleField, id: 'titles1' }];
  return rows.map((row) => (
    <ContentFormRow
      title={row.title}
      col1={row.col1}
      col2={row.col2}
      key={`chapters.${index}.field.${row.id}`}
    />
  ));
};
