import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Grid, Pagination, PaginationItem, Tooltip } from '@mui/material';

import { FormText } from './FormText';
import { FormDateTimePicker } from './FormDateTimePicker';
import { ContentFormRow } from './ContentFormRow';

export const DatasetFields = ({ chapterIndex, contentIndex, maxEntities }) => {
  const { control } = useFormContext();
  const { fields: datasets, append: datasetAppend } = useFieldArray({
    control,
    name: `chapters.${chapterIndex}.content.${contentIndex}.datasets`,
  });

  const [index, setIndex] = React.useState(1);

  const handleChange = (event, newPage) => {
    setIndex(newPage);
  };

  const newDataset = () => {
    datasetAppend({ dataSourceId: '' });
    setIndex(datasets.length + 1);
  };

  const dataSourceIdField = (
    <FormText
      name={`chapters.${chapterIndex}.content.${contentIndex}.datasets.${index - 1}.dataSourceId`}
      label="Data Source ID"
      sx={{ width: '100%' }}
      required
    />
  );

  const dataIdField = (
    <FormText
      name={`chapters.${chapterIndex}.content.${contentIndex}.datasets.${index - 1}.dataId`}
      label="Data ID"
      sx={{ width: '100%' }}
      required
    />
  );

  const fromField = (
    <FormDateTimePicker
      name={`chapters.${chapterIndex}.content.${contentIndex}.datasets.${index - 1}.from`}
      label="From"
    />
  );

  const toField = (
    <FormDateTimePicker
      name={`chapters.${chapterIndex}.content.${contentIndex}.datasets.${index - 1}.to`}
      label="To"
    />
  );

  const labelField = (
    <FormText
      name={`chapters.${chapterIndex}.content.${contentIndex}.datasets.${index - 1}.label`}
      label="Label"
      sx={{ width: '100%' }}
    />
  );

  const rows = [
    {
      title: 'Source',
      col1: dataSourceIdField,
      col2: dataIdField,
      key: `chapters.${chapterIndex}.content.${contentIndex}.datasets.${index - 1}.source`,
    },
    {
      title: 'Range',
      col1: fromField,
      col2: toField,
      key: `chapters.${chapterIndex}.content.${contentIndex}.datasets.${index - 1}.range`,
    },
    {
      title: 'Title & labels',
      col1: labelField,
      key: `chapters.${chapterIndex}.content.${contentIndex}.datasets.${index - 1}.labels`,
    },
  ];

  return (
    <>
      {datasets.length > 0 && (
        <>
          {rows.map((row) => (
            <ContentFormRow title={row.title} col1={row.col1} col2={row.col2} key={row.key} />
          ))}
        </>
      )}

      <Grid container justifyContent="center">
        <Pagination
          count={datasets.length}
          page={index}
          onChange={handleChange}
          color="primary"
          variant="outlined"
          hidePrevButton
          hideNextButton
          siblingCount={5}
          sx={{ marginTop: 4 }}
        />
        {datasets.length < maxEntities ? (
          <PaginationItem variant="outlined" page="+" onClick={newDataset} sx={{ marginTop: 4 }} />
        ) : (
          <Tooltip title="Reached limit" placement="top">
            <PaginationItem variant="outlined" page="+" sx={{ marginTop: 4 }} />
          </Tooltip>
        )}
      </Grid>
    </>
  );
};
