import React from 'react';

import { FormText } from './FormText';
import { ContentFormRow } from './ContentFormRow';

export const ConfigurationFields = () => {
  const outputFields = <FormText name="filename" label="Filename" sx={{ width: '100%' }} />;

  const rows = [{ title: 'Output', col1: outputFields, id: 'file1' }];
  return rows.map((row) => (
    <ContentFormRow
      title={row.title}
      col1={row.col1}
      col2={row.col2}
      key={`configuration.field.${row.id}`}
    />
  ));
};
