import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, Grid, Tooltip } from '@mui/material';

import { FormText } from './FormText';
import { FormSelect } from './FormSelect';
import { FormTitle } from './FormTitle';
import { PieChartFields } from './PieChartFields';
import { TimeSeriesFields } from './TimeSeriesFields';

const contentTypes = [
  { value: 'text', label: 'Text' },
  { value: 'figure', label: 'Figure' },
  { value: 'pagebreak', label: 'Page break' },
];

const figureTypes = [
  { value: 'pie_chart', label: 'Pie chart' },
  { value: 'time_series', label: 'Time series' },
];

export const FigureGeneralFields = ({ chapterIndex, contentIndex }) => {
  const { watch } = useFormContext();
  const figureType = watch(`chapters.${chapterIndex}.content.${contentIndex}.figure_type`);
  return (
    <>
      <Box
        sx={{ flex: 1, padding: 2 }}
        key={`chapters.${chapterIndex}.content.${contentIndex}.fields`}
      >
        <Grid container spacing={4}>
          <FormTitle title="General" />
          <Grid container item spacing={3} md={4}>
            <Grid item xs={12} md={12}>
              <FormSelect
                name={`chapters.${chapterIndex}.content.${contentIndex}.type`}
                label="Type"
                menuItems={contentTypes}
                sx={{ width: '100%' }}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <FormSelect
                name={`chapters.${chapterIndex}.content.${contentIndex}.figure_type`}
                label="Figure type"
                menuItems={figureTypes}
                defaultValue=""
                sx={{ width: '100%' }}
                required
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Tooltip
              title="Only to help you track your contents in Rapid Report."
              placement="bottom"
            >
              <>
                <FormText
                  sx={{ width: '100%', height: '10ch', paddingBottom: 16 }}
                  name={`chapters.${chapterIndex}.content.${contentIndex}.description`}
                  label="Description"
                  multiline
                  rows={4.5}
                  inputProps={{ maxLength: 80 }}
                />
              </>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
      {figureType === 'pie_chart' ? (
        <PieChartFields chapterIndex={chapterIndex} contentIndex={contentIndex} />
      ) : (
        figureType === 'time_series' && (
          <TimeSeriesFields chapterIndex={chapterIndex} contentIndex={contentIndex} />
        )
      )}
    </>
  );
};
