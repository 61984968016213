import React from 'react';
import { StarOutline } from '@mui/icons-material';

import { GeneralFormCard } from '../ui/GeneralFormCard';

export const General = () => {
  return (
    <GeneralFormCard
      title="General"
      subheader="Top level settings. Can be overridden by chapter specific settings."
      icon=<StarOutline color="primary" />
    />
  );
};
