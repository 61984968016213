import * as R from 'ramda';

export const getHost = () => window.location.host;
export const getProtocol = () => window.location.protocol;

// Random string generator
const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
const getChar = () => charset.charAt(Math.floor(Math.random() * charset.length));
export const generateString = (length = 14) =>
  R.pipe(R.range(0), R.reduce((x) => `${x}${getChar()}`)(''))(length);

// Find index of chapter with id
export const indexOf = (chapters, chapterId) => R.findIndex(R.propEq('id', chapterId))(chapters);

// Range generator
export const range = (from, to, step) => R.map((i) => i * step)(R.range(from, R.divide(to, step)));

export const urlParams = (obj) =>
  Object.entries(obj)
    .map((pair) => pair.join('='))
    .join('&');

// Get url of service in the same domain
export const appUrl = (app, relativePath, params) =>
  `${getProtocol()}//${getHost().replace(/[^.]+/, app)}${relativePath}${
    params ? `?${urlParams(params)}` : ''
  }`;
