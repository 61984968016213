import * as React from 'react';
import {
  CardHeader,
  CardContent,
  CardActions,
  Collapse,
  IconButton,
  Divider,
  Toolbar,
  Box,
  styled,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FactCheckOutlined, BookOutlined } from '@mui/icons-material/';

import { PageTabs, TabPanel, TabContext } from './PageTabs';
import { GeneralFields } from './GeneralFields';
import { CoverPageFields } from './CoverPageFields';
import { StyledCard } from './StyledCard';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export const GeneralFormCard = ({ title, subheader, icon, advancedFields }) => {
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const tabHeaders = [
    { label: 'features', icon: <FactCheckOutlined /> },
    { label: 'cover page', icon: <BookOutlined /> },
  ];
  const tabContent = [
    { name: 'features', component: <GeneralFields /> },
    { name: 'cover page', component: <CoverPageFields /> },
  ];
  const [generalTab, setGenTab] = React.useState(0);

  return (
    <Box sx={{ flex: 1, maxWidth: 1100 }} key="formcard.general">
      <TabContext.Provider value={{ generalTab }}>
        <Toolbar />
        <CardHeader
          avatar={icon}
          title={title}
          subheader={subheader}
          sx={{ flex: 1, height: 92 }}
        />
        <Divider />
        <PageTabs
          headers={tabHeaders}
          tabIndex={generalTab}
          setTabIndex={setGenTab}
          keyPrefix="general"
        />

        <StyledCard>
          {tabContent.map((item, index) => (
            <TabPanel value={generalTab} index={index} key={`generaltab-${item.name}`}>
              {item.component}
            </TabPanel>
          ))}
          {advancedFields && (
            <CardActions disableSpacing>
              <ExpandMore expand={expanded} onClick={handleExpandClick} aria-expanded={expanded}>
                <ExpandMoreIcon />
              </ExpandMore>
            </CardActions>
          )}
          <Collapse in={expanded} timeout="auto">
            <CardContent>
              <Divider sx={{ margin: '0px 0px 6px 0px' }}>Advanced</Divider>
              {advancedFields}
            </CardContent>
          </Collapse>
        </StyledCard>
      </TabContext.Provider>
    </Box>
  );
};
