import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import MuiSnackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const Snackbar = ({ message, open, setOpen }) => {
  const close = () => {
    setOpen(false);
  };
  return (
    // Types: error, warning, info, success
    <MuiSnackbar open={open} autoHideDuration={6000} onClose={() => setOpen(false)}>
      <Alert onClose={() => setOpen(false)} severity={message.color} sx={{ width: '100%' }}>
        {message.text}
      </Alert>
    </MuiSnackbar>
  );
};
