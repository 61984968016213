import React from 'react';
import { Settings } from '@mui/icons-material';

import { ConfigurationFormCard } from '../ui/ConfigurationFormCard';

export const Configuration = () => {
  return (
    <ConfigurationFormCard
      title="Configuration"
      subheader="File specific settings."
      icon=<Settings color="primary" />
    />
  );
};
