import { Card, styled } from '@mui/material';

export const StyledCard = styled(Card)({
  // background: 'rgba(60,70,80,0.22)',
  background: 'linear-gradient(0deg, rgba(60,70,80,0.22) 60%, rgba(77,183,255,0.26) 225%)',
  maxWidth: 1100,
  flex: 1,
  margin: 12,
  marginTop: 0,
});
